import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  users: [],
  totalCount: 0,
  loadingUpBalance: false,
  userDetails: null,
  loadingEndGame: false,
  totalUsers: 0,
  liveUsers: 0,
  loadingRefferalList: false,
  refferalsList: [],
  totalRefferalsList: 0,
  loadingEarningsList: false,
  earningsList: [],
  totalEarningsList: 0,
  totalZeroRefferals: 0,
  ipList:[],
  totalIpCounts:0,
  loadingIpList: false
};

const slice = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    clearUsersList(state) {
      state.users = [];
      state.totalCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Act.getUsers.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getUsers.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      users: payload.data,
      totalCount: payload.totalcount,
      totalUsers: payload.totalCount,
      liveUsers: payload.liveCount,
    }));
    builder.addCase(Act.getUsers.rejected, (state, action) => ({
      ...state,
      loading: false,
      users: [],
      totalCount: 0,
    }));

    ///change users status
    builder.addCase(Act.updateUser.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.updateUser.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.updateUser.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    builder.addCase(Act.updateUserStatus.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.updateUserStatus.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.updateUserStatus.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));


    //add user 
    builder.addCase(Act.AddUser.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.AddUser.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.AddUser.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));
    builder.addCase(Act.updateUserBalance.pending, (state, action) => {
      state.loadingUpBalance = true;
    });
    builder.addCase(Act.updateUserBalance.fulfilled, (state, action) => {
      state.loadingUpBalance = false;
      // state.userDetails = action.payload.data
    });
    builder.addCase(Act.updateUserBalance.rejected, (state, action) => {
      state.loadingUpBalance = false;
    });
    builder.addCase(Act.getUserDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.getUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.userDetails = action.payload;
    });
    builder.addCase(Act.getUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.userDetails = null;
    });
    builder.addCase(Act.gameEndApi.pending, (state, action) => {
      state.loadingEndGame = true
    });
    builder.addCase(Act.gameEndApi.fulfilled, (state, action) => {
      state.loadingEndGame = false
    });
    builder.addCase(Act.gameEndApi.rejected, (state, action) => {
      state.loadingEndGame = false
    });

    //addSub admin
    builder.addCase(Act.addSubAdminApi.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.addSubAdminApi.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
      }));
    builder.addCase(Act.addSubAdminApi.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    builder.addCase(Act.deleteUserApi.pending, (start, action) => ({
      ...start,
      delloading: true,
    }));
    builder.addCase(Act.deleteUserApi.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        delloading: false,
        users: state?.users?.filter((user) => user?._id !== payload?.data?._id)
      }));
    builder.addCase(Act.deleteUserApi.rejected, (state, action) => ({
      ...state,
      delloading: false,
    }));
    builder.addCase(Act.refferalsListApi.pending, (state, action) => {
      state.loadingRefferalList = true;
    })
    builder.addCase(Act.refferalsListApi.fulfilled, (state, action) => {
      state.loadingRefferalList = false;
      state.refferalsList = action.payload.data
      state.totalRefferalsList = action.payload.totalcount;
      state.totalZeroRefferals = action.payload.totalZeroRefferals || 0
    })
    builder.addCase(Act.refferalsListApi.rejected, (state, action) => {
      state.loadingRefferalList = false;
    })
    builder.addCase(Act.earningsListApi.pending, (state, action) => {
      state.loadingEarningsList = true;
    })
    builder.addCase(Act.earningsListApi.fulfilled, (state, action) => {
      state.loadingEarningsList = false;
      state.earningsList = action.payload.data
      state.totalEarningsList = action.payload.totalcount;
    })
    builder.addCase(Act.earningsListApi.rejected, (state, action) => {
      state.loadingEarningsList = false;
    })
    builder.addCase(Act.ipListApi.pending,(state,action) => {
      state.loadingIpList = true
    })
    builder.addCase(Act.ipListApi.fulfilled,(state,action) => {
      state.ipList = action.payload.data
      state.totalIpCounts = action.payload.totalcount
      state.loadingIpList = false
    })
    builder.addCase(Act.ipListApi.rejected,(state,action) => {
      state.loadingIpList = false
    })
  },
});

export default slice;
