import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { get } from "helpers/api_helper";
import { GET_STATISTICS_EXCELL_FILE, GET_STATISTICS_GRAPH, GET_STATISTICS_LINE_COUNT } from "helpers/url_helper";
import { getSocketEmit, getValuesSocketData } from "helpers/secretManager";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import moment from "moment-timezone";
import { toast } from "react-toastify";
import { formatNumber } from "helpers/common";
import useSocket from "hooks/useSocket";
import ExportStatistics from "components/Modals/ExportStatictics";


const monthsOptions = [
    { "value": 1, "label": "January" },
    { "value": 2, "label": "February" },
    { "value": 3, "label": "March" },
    { "value": 4, "label": "April" },
    { "value": 5, "label": "May" },
    { "value": 6, "label": "June" },
    { "value": 7, "label": "July" },
    { "value": 8, "label": "August" },
    { "value": 9, "label": "September" },
    { "value": 10, "label": "October" },
    { "value": 11, "label": "November" },
    { "value": 12, "label": "December" }
];

const Statistics = () => {
    const { socket, socketConnect } = useSocket();
    const [userStatistics, setUserStatistics] = useState([]);
    const [btncStatistics, setBtncStatistics] = useState([]);
    const [otherStatistics, setOtherStatistics] = useState([]);
    const [yearsOptions, setYearsOptions] = useState([]);
    const [filters, setFilters] = useState({
        month: moment().tz("Etc/GMT-4").month() + 1,
        year: moment().year()
    })
    const [showExportModal, setShowExportModal] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // Fixed start year
        const fixedStartYear = 2024; // Always start from 2024
        const currentYear = moment().year(); // Get the current year

        // Extend 10 years from the current year
        const endYear = currentYear + 10;

        // Always start from 2024
        const startYear = fixedStartYear;

        const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => ({
            value: startYear + index,
            label: (startYear + index).toString()
        }));

        setYearsOptions(years);
        if (socketConnect) {
            getApiData(filters.month, filters.year);
        }

    }, [socketConnect]);




    async function getApiData(month, year) {
        try {
            // const { signature, nonce } = await getValuesSocketData();
            setLoading(true);
            // let url = `${GET_STATISTICS_GRAPH}`;

            // const response = await getSocketEmit(socket, "getStatisticsData", { key: "graphData", month: month, year: year });
            // if (response?.status && typeof response?.data !== "string") {
            //     setUserStatistics(response?.data?.userStatistics);
            //     setBtncStatistics(response?.data?.btncStatistics);
            //     setOtherStatistics(response?.data?.otherStatistics);
            // }
            // const response = await get(url, { signature, nonce })

            // if (response?.status === 'success') {
            //     setUserStatistics(response?.data?.userStatistics);
            //     setBtncStatistics(response?.data?.btncStatistics);
            //     setOtherStatistics(response?.data?.otherStatistics);
            // }
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const searchFilter = () => {
        console.log("filter search", filters)
        getApiData(filters.month, filters.year);
    }

    const clearFilter = () => {
        getApiData(
            moment().tz("Etc/GMT-4").month() + 1,
            moment().year()
        );
    }

    const getUniqueCurrencies = (data) => {
        if (!Array.isArray(data)) return [];
        return Array.from(
            new Set(
                data.flatMap((item) =>
                    Array.isArray(item?.others)
                        ? item.others.map((crypto) => crypto?._id).filter(Boolean)
                        : []
                )
            )
        );
    };

    const preprocessData = (data, uniqueCurrencies) => {
        if (!Array.isArray(data) || !Array.isArray(uniqueCurrencies)) return [];
        return data.map((item) => {
            const cryptoData = uniqueCurrencies.reduce((acc, currency) => {
                const crypto = item?.others?.find((other) => other?._id === currency);
                acc[currency] = crypto?.totalAmount ?? 0;
                return acc;
            }, {});
            return {
                date: item?.date ?? "N/A",
                rounds: item?.rounds ?? 0,
                ...cryptoData,
            };
        });
    };

    // Step 1: Get unique cryptocurrencies
    const uniqueCurrencies = getUniqueCurrencies(otherStatistics);

    // Step 2: Preprocess data
    const processedData = preprocessData(otherStatistics, uniqueCurrencies);


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // Sort the payload data in ascending order by value
            // const sortedPayload = [...payload].sort((a, b) => a.value - b.value);
            const sortedPayload = [...payload].sort((a, b) => {
                if (a.name === 'Rounds' && b.name !== 'Rounds') {
                    return -1; // Place "rounds" at the top
                }
                if (a.name !== 'Rounds' && b.name === 'Rounds') {
                    return 1; // Keep "rounds" at the top
                }
                return b.value - a.value; // Ascending sort for other items
            });

            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label">{`${label}`}</p>
                    {sortedPayload.map((entry, index) => (
                        <p key={`item-${index}`}>{`${entry.name}: ${formatNumber(entry.value)}`}</p>
                    ))}
                </div>
            );
        }

        return null;
    };

    const exportData = async () => {
        setShowExportModal(true);
    }


    return (
        <>
            {loading && (
                <div className="main-loader">
                    <FadeLoader size={1000} />
                </div>
            )}
            <ExportStatistics
                show={showExportModal}
                handleClose={() => setShowExportModal(false)}
            />
            <section className="section">

                <div className="section-body">
                    <div className="card">
                        <div className="row justify-content-between">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                <select value={filters.month} className="mx-2 my-2" style={{ width: 140, height: 40 }} placeholder="Select month" onChange={(e) => setFilters((pre) => ({ ...pre, month: e?.target?.value }))}>
                                    <option value={""}></option>
                                    {monthsOptions?.map((item, key) => (
                                        <option key={key} value={item.value}>{item.label}</option>
                                    ))}
                                </select>

                                <select value={filters.year} className="mx-2 my-2" style={{ width: 140, height: 40 }} placeholder="Select year" onChange={(e) => setFilters((pre) => ({ ...pre, year: e?.target?.value }))}>
                                    <option value={""}></option>
                                    {yearsOptions?.map((item, key) => (
                                        <option key={key} value={item?.value}>{item?.label}</option>
                                    ))}
                                </select>
                                <button type="btn" onClick={() => searchFilter()} className="btn btn-primary mx-2 my-2">
                                    Search
                                </button>
                                <button type="btn" onClick={() => clearFilter()} className="btn btn-primary mx-2 my-2">
                                    Clear
                                </button>

                                {/* <button type="btn" onClick={() => exportData()} className=" float-right btn btn-primary mx-2 my-2">
                                    Export
                                </button> */}

                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >

                                <div className="card-header">
                                    <h2 className="section-title">Users Statistics</h2>
                                </div>

                                <div style={{ width: "100%", height: 500 }}>

                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={userStatistics}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Legend />
                                            <Bar dataKey="dauCount" stackId="a" fill="#004bc2" />
                                            <Bar dataKey="dnuCount" stackId="a" fill="#91bcff" />

                                        </BarChart>
                                    </ResponsiveContainer>

                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                <h2 className="section-title">Btnc Statistics</h2>
                                <div style={{ width: "100%", height: 500 }}>

                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={btncStatistics}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Legend />
                                            <Bar dataKey="btnc" stackId="a" fill="#8884d8" />

                                        </BarChart>
                                    </ResponsiveContainer>

                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                <h2 className="section-title">Crypto Statistics / Total Rounds</h2>
                                <div style={{ width: "100%", height: 500 }}>

                                    <ResponsiveContainer width="100%" height={400}>
                                        <BarChart
                                            data={processedData}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="date" />
                                            <YAxis />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Legend />
                                            {/* Separate Bar for Rounds */}
                                            <Bar dataKey="rounds" fill="#8884d8" name="Rounds" />
                                            {/* Stacked Bars for Cryptocurrencies */}
                                            {uniqueCurrencies.map((currency, index) => (
                                                <Bar
                                                    key={currency}
                                                    dataKey={currency}
                                                    stackId="cryptos"
                                                    fill={`hsl(${index * 60}, 70%, 50%)`} // Dynamic colors
                                                    name={currency.toUpperCase()} // Label in the legend
                                                />
                                            ))}
                                        </BarChart>
                                    </ResponsiveContainer>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Statistics;
