export const LOGIN_USER = "/users/admin-login";
export const FORGET_PASSWORD = "/admin/forgotPassword";
export const UPDATE_ADMIN = "/users/update/";
export const VIEW_USER = "/users/session";
export const USER_DETAILS = "/users/user-detail/";
export const RESET_PASSWORD = "/admin/password";

// File
export const POST_FILE = "/settings/uploadFile";
export const POST_REMOVE_FILE = "/settings/remove";

///Setting apis
export const GET_SETTINGS = "/settings/view";
export const UPDATE_SETTINGS = "/settings/update";
export const UPDATE_GAME_SETTING = "/settings/game/update";
export const UPDATE_ENERGY_SETTING = "/settings/energy/update";
export const UPDATE_LEADERBOARD_SETTING = "/settings/leaderboard/update";
export const UPDATE_MAINTENANCE_SETTING = "/settings/maintenance/update";

//// user

export const GET_USERS = "/users/list";
export const ADD_USER = "/users/add";
export const UPDATE_USER = "/users/";
export const GET_USER_DETAILS = "/users";
export const UPDATE_USER_STATUS = "/users/block/unblock/";

//levels
export const GET_LEVELS = "/level/list";
export const ADD_LEVEL = "/level/create";
export const UPDATE_LEVEL = "/level/edit";

//Roadmaps
export const GET_ROADMAP_LIST = "/roadmaps/list";
export const ADD_ROADMAP = "/roadmaps/add";
export const EDIT_ROADMAP = "/roadmaps/";
export const DELETE_ROADMAP = "/roadmaps/";

// pages
export const GET_PAGE_LIST = "/admin/pages/list";
export const ADD_PAGE = "/admin/pages/add";
export const EDIT_PAGE = "/admin/pages/";
export const DELETE_PAGE = "/admin/pages/";

//language
export const GET_LANGUAGE_LIST = "/admin/language";
export const ADD_LANGUAGE = "/admin/language/add";
export const EDIT_LANGUAGE = "/admin/language/";
export const DELETE_LANGUAGE = "/admin/language/";
export const GET_LANGUAGE_CODES = "/admin/language/codes";

//badges
export const GET_BADGE_LIST = "/admin/badge/list";
export const ADD_BADGE = "/admin/badge/add";
export const EDIT_BADGE = "/admin/badge/";
export const DELETE_BADGE = "/admin/badge/";

//avatar
export const GET_AVATAR_LIST = "/avatar/list";
export const ADD_AVATAR = "/avatar/add";
export const EDIT_AVATAR = "/avatar/";
export const DELETE_AVATAR = "/avatar/";

//avatar category
export const GET_AVATAR_CATEGORY_LIST = "/avatar/category/list";
export const ADD_AVATAR_CATEGORY = "/avatar/category/add";
export const EDIT_AVATAR_CATEGORY = "/avatar/category/";
export const DELETE_AVATAR_CATEGORY = "/avatar/category/";

//avatar characterstic
export const GET_AVATAR_CHARACTERSTIC_LIST = "/avatar/characterstic/list";
export const ADD_AVATAR_CHARACTERSTIC = "/avatar/characterstic/add";
export const EDIT_AVATAR_CHARACTERSTIC = "/avatar/characterstic/";
export const DELETE_AVATAR_CHARACTERSTIC = "/avatar/characterstic/";

export const GET_CSRF_TOKEN = "/users/token";


//game 
export const GAME_HISTORY_LIST = "/games/history-list";
export const GAME_HISTORY_DETAILS = "/games/details";
export const GAME_LEADERBOARD_LIST = "/leaderboard/list";
export const UPDATE_LEADERBOARD = "/leaderboard/";
export const CLAIM_HISTORY_LIST = "/claim/adminList";
export const ENERGY_HISTORY_LIST = "/energy/list";

//logout 
export const USER_LOGOUT = "/users/logout";
export const CHANGE_PASSWORD = "/users/admin/password";

//task 
export const TASK_LIST = "/tasks/list";
export const ADD_TASK = "/tasks/add";
export const UPDATE_DELETE_TASK = "/tasks/";
export const COINS_LIST = "/settings/coinsList";
export const EXPORT_USERS_LIST = "/users/export/list";
export const EXPORT_GAME_LIST = "/games/export/list";
export const EXPORT_CLAIM_LIST = "/claim/export/list";

//logs
export const LOGS_LIST = "/users/logs/list";

//balance
export const UPDATE_USER_BALANCE = "/users/balance/";

//game 
export const GAME_END_BY_ADMIN = "/games/end/";

//statistics
export const GET_STATISTICS_EXCELL_FILE = "/statistics/excel";
export const GET_STATISTICS_GRAPH = "/statistics/graph";
export const GET_STATISTICS_COUNT = "/statistics/counts";
export const REMAINING_CRYPTO = "/statistics/remain/crypto";
export const GET_STATISTICS_LINE_COUNT = "/statistics/line/count";

//subadmins:-
export const ADD_SUB_ADMIN = "/users/admin-register";

export const DELETE_USER = "/users/delete";

export const BTNC_HISTORY_LIST = "/btnc/history";

export const USER_TASKS_LIST = "/tasks/completed/list";
export const USER_REFFERALS_LIST = "/referrals/list";
export const USER_EARNINGS_LIST = "/referrals/history";
export const USER_IP_LIST = "/users/ipHistory/list"

