import { DATE_FORMAT, formatNumber } from "helpers/common";
import React from "react";

// import UnControll

const Columns = ({ }) => [
  {
    dataField: "name",
    text: "Name",
    formatter: (_, row) => <span className="text-capitalize">{row?.name}</span>,
  },
  {
    dataField: "symbol",
    text: "Symbol",
    formatter: (_, row) => <span className="text-capitalize">{row?.symbol}</span>,
  },
  {
    dataField: "line",
    text: "Line",
    formatter: (_, row) => <span className="text-capitalize">{row?.line}</span>,

  },
  {
    dataField: "remainingAmount",
    text: "Remaining Amount",
    formatter: (_, row) => <span className="text-capitalize">{formatNumber(row?.remainingAmount)}</span>,
  },
  {
    dataField: "totalAmount",
    text: "Total Amount",
    formatter: (_, row) => <span className="text-capitalize">{formatNumber(row?.totalAmount)}</span>,
  },
  {
    dataField: "Enabled At",
    text: "EnabledAt",
    formatter: (_, row) => row?.enabledAt && DATE_FORMAT(row.enabledAt),
  }
];

export default Columns;
