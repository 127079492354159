// ipAddress, deviceInfo, country , city, date_created_utc
import { DATE_FORMAT } from "helpers/common";
import React from "react";

const Columns = ({ }) => [
    {
        dataField: "ipAddress",
        text: "IP Address",
        formatter: (_, row) => <span className="text-capitalize">{row?.ipAddress}</span>,
    },

    {
        dataField: "deviceInfo",
        text: "Device Info",
        formatter: (_, row) => <span className="text-capitalize">{row?.deviceInfo}</span>,
    },
    {
        dataField: "country",
        text: "Country",
        formatter: (_, row) => <span className="text">{row?.ipInfo?.country}</span>,
    },
    {
        dataField: "city",
        text: "City",
        formatter: (_, row) =>  <span className="text">{row?.ipInfo?.city}</span>,
    },
    {
        dataField: "date_created_utc",
        text: "Date",
        formatter: (_, row) => <span className="text-capitalize">{row?.date_created_utc && DATE_FORMAT(row?.date_created_utc)}</span>,
    }
];

export default Columns;