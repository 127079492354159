import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { getSocketEmit } from "helpers/secretManager";
import FormikForm from "components/shared/FormikForm";
import moment from "moment";
import { formatNumber } from "helpers/common";
import useSocket from "hooks/useSocket";
import { Card, CardBody, Col, Row } from "reactstrap";
import { CardHeader } from "react-bootstrap";



const InputFieldsForSearch = [
    {
        name: "datepicker",
        type: "datepicker",
        label: "",
        placeholder: "",
    }
];

const initialValuesForFilter = {
    startDate: moment(),
    endDate: moment(),
};

const StatisticsWithFilter = () => {
    const { socket, socketConnect } = useSocket();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [dateFilter, setDateFilter] = useState({
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
    });
    const [filter, setFilter] = useState({
        startDate: null,
        endDate: null,
    });
    const [dataCounts, setDataCounts] = useState({
        min3Games: 0,
        min3Tasks: 0,
        // dailyActiveUsers: 0,
        linkedBitanica: 0,
    });

    const handleFilterForm = (info) => {
        console.log("info", info)
        if (!dateFilter.startDate || !dateFilter.endDate) {
            return;
        }
        setFilter({
            ...filter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate
        });
    };

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
        setDateFilter((prevFilter) => ({
            ...prevFilter,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
        }));
        setShowPicker(false);
    };


    useEffect(() => {
        if (socketConnect) {
            getApiData(filter);
        }
    }, [filter, socketConnect]);

    async function getApiData(queryData) {
        try {
            setLoading(true);
            const response1 = await getSocketEmit(socket, "getStatisticsData", { key: "min3Games", startDate: queryData?.startDate, endDate: queryData?.endDate });
            setDataCounts(pre => ({ ...pre, min3Games: typeof response1?.data !== "string" ? response1?.data : 0 }));

            const response2 = await getSocketEmit(socket, "getStatisticsData", { key: "min3Tasks", startDate: queryData?.startDate, endDate: queryData?.endDate });
            setDataCounts(pre => ({ ...pre, min3Tasks: typeof response2?.data !== "string" ? response2?.data : 0 }));

            const response3 = await getSocketEmit(socket, "getStatisticsData", { key: "linkedBitanica", startDate: queryData?.startDate, endDate: queryData?.endDate });
            setDataCounts(pre => ({ ...pre, linkedBitanica: typeof response3?.data !== "string" ? response3?.data : 0 }));

            // const response4 = await getSocketEmit(socket, "getStatisticsData", { key: "dailyActiveUsers", startDate: queryData?.startDate, endDate: queryData?.endDate });
            // console.info("response4?.data", response4?.data)
            // setDataCounts(pre => ({ ...pre, dailyActiveUsers: typeof response4?.data !== "string" ? response4?.data : 0 }));

        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {loading && (
                <div className="main-loader">
                    <FadeLoader size={1000} />
                </div>
            )}
            <div className="row justify-content-between">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                    <Row>
                        <div className="card">
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <div className="card-header">
                                        <h5 className="section-title">Users Counts</h5>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Col sm={12} lg={12}>
                            <FormikForm
                                initialValues={initialValuesForFilter}
                                onSubmit={handleFilterForm}
                                loading={loading}
                                inputFields={InputFieldsForSearch}
                                buttonText={"Search"}
                                dateRange={dateFilter}
                                showPicker={showPicker}
                                setShowPicker={setShowPicker}
                                handleSelect={handleSelect}
                                col={4}
                            />
                        </Col>
                        <Col sm={12} lg={12} className="my-2">
                            <Row>
                                <Col lg="3" md="4" sm="6" className="my-2">

                                    <Card className="my-2">
                                        <CardHeader>
                                            Completed 3 tasks
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.min3Tasks)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="4" sm="6" className="my-2">
                                    <Card className="my-2">
                                        <CardHeader>
                                            Played at Least 3 Games
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.min3Games)}
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col lg="3" md="4" sm="6" className="my-2">

                                    <Card className="my-2">
                                        <CardHeader>
                                            Linked to Bitanica
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.linkedBitanica)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                {/* <Col lg="3" md="4" sm="6" className="my-2">
                                    <Card className="my-2">
                                        <CardHeader>
                                            Daily Active
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.dailyActiveUsers)}
                                        </CardBody>
                                    </Card>

                                </Col> */}
                            </Row>
                        </Col>
                    </Row>
                </div>

            </div>
        </>
    );
};

export default StatisticsWithFilter;
