import React, { useEffect, useState } from "react";
import FormikForm from "components/shared/FormikForm";
import { useDispatch, useSelector } from "react-redux";
import { ipListApi } from "store/actions";
import Table from "components/Table";
import Column from "./Column";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search...",
  },
];
const initialValuesForFilter = {
  search: "",
};
const List = ({ userDetails }) => {
  const dispatch = useDispatch();
  const { ipList, totalIpCounts, loadingIpList } = useSelector(
    ({ Users }) => Users
  );
  const [columns, setColumns] = useState([{ dataField: "", text: "" }]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    userId: userDetails?._id,
  });
  useEffect(() => {
    if (userDetails?._id) {
      getApiData({ ...filter, userId: userDetails?._id });
      setColumns(Column({}));
    }
  }, [userDetails, filter]);

  async function getApiData(query) {
    try {
      dispatch(ipListApi({ data: query }));
    } catch (error) {
      console.log(error);
    }
  }

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 10,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search
    });
  };
  console.info(ipList,"IPLIST")
  console.info(columns,"COLUMNS")
  return (
    <div className="section-body">
      <div className="card">
        <div className="row justify-content-between">
          <div className="col-12">
            <div className="card-header">
              <FormikForm
                initialValues={initialValuesForFilter}
                onSubmit={handleFilterForm}
                loading={loadingIpList}
                inputFields={InputFieldsForSearch}
                buttonText={"Search"}
                // totalCount={totalIpCounts}
              />
            </div>
          </div>
        </div>
      </div>
      {ipList?.length > 0 && (
        <Table
          data={ipList}
          columns={columns}
          setFilter={setFilter}
          total={totalIpCounts}
          filter={filter}
        />
      )}
      {!loadingIpList && ipList?.length === 0 && (
        <p style={{ textAlign: "center" }}>No Data Found</p>
      )}
    </div>
  );
};

export default List;
